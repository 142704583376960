import React from "react";
import loadable from "@loadable/component";
import { dynamicHeadlines } from "../../utils/url-utils";
import ogImage from "../../images/global_assets/og-image.png";
import LpLayout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Hero from "../../components/Hero/hero";
import {
  AutoLPHero,
  AutoLPWhiteGlove,
  AutoLPLargeFeatures,
  AutoLPCTA,
  autoCsSlides,
  autoLpSuccess,
} from "../../data/landing/auto";

const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));

const whiteGloveImport = "about-us-white-glove.png";

const AutoLp = () => {
  return (
    <LpLayout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Automotive Demo | Point-of-Sale"
        description="Run and grow your business more efficiently than ever with a powerful point-of-sale that helps you stay connected with your customers"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero
        dynamicH1={dynamicHeadlines()}
        withBullets={[
          "Collect customer payments easier",
          "Manage appointments and team schedules",
          "Improve customer retention",
        ]}
        sectionData={AutoLPHero}
        circleBg={false}
      />

      {/* <Badges /> */}

      <section style={{ marginTop: "60px" }}>
        <SuccessStories sectionData={autoLpSuccess} slides={autoCsSlides} />
        {/* <TestmonialReviews sectionData={AutoLPTestimonials} /> */}
      </section>

      {/* <PartnerLogos /> */}

      <LargeFeatures
        sectionData={AutoLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />

      <WhiteGlove
        sectionData={AutoLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />

      <LargeCta sectionData={AutoLPCTA} />
    </LpLayout>
  );
};

export default AutoLp;
